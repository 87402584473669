//Thanks: http://www.technofattie.com/2014/07/27/easy-loading-indicator-when-switching-views-in-angular.html
function LoadingController($rootScope, $log, $route) {
  this.isRouterLoading = false;

  let loadingFunction = $rootScope.$on('$routeChangeStart', (event, next) => {
    this.isRouterLoading = true;
  });

  let unloadFunction = $rootScope.$on('$routeChangeSuccess', () => {
    this.isRouterLoading = false;
    this.isError = false;
  });

  let errorFunction = $rootScope.$on('$routeChangeError', (ev, current, previous, rejection) => {
    $log.error('failed to change route', rejection);
    this.isRouterLoading = false;
    this.isError = true;
  });

  // We don't need to manually need to reset our state variables...unless
  // the route that had the issue is '/', in which case using a simply 'href'
  // will not reload the path
  this.goHomeAndDismiss = () => {
    this.isRouterLoading = false;
    this.isError = false;
    $route.reload();
  };

  this.$onDestroy = () => {
    errorFunction();
    loadingFunction();
    unloadFunction();

    // Not strictly needed, but makes order more important in resolve steps above
    this.isRouterLoading = false;
    this.isError = false;
  };
}

angular.module('sq.common.loading.component', [])
.component('sqLoading', {
  template: `
    <md-progress-circular ng-show='$ctrl.isRouterLoading && !$ctrl.isError' class='search-loading'
    md-mode="indeterminate"></md-progress-circular>
    <md-card ng-if='$ctrl.isError' style='transform: translate(0px, -50%);left: -50%;position: relative;'>
      <md-card-title>
          <md-card-title-text>
            <span class="md-headline">Failed to load page</span>
            <span class="md-subhead">Please try again</span>
          </md-card-title-text>
      </md-card-title>
      <md-card-actions layout="row" layout-align="end center">
        <md-button class='md-icon-button' ng-click='$ctrl.goHomeAndDismiss()' class='link'>
          <md-icon class='material-icons'>refresh</md-icon>
        </md-button>
      </md-card-actions>
    </md-card>
  </span>`,
  controller: LoadingController,
});